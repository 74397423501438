import React from "react"

import Layout from "../components/layout"
import ContactComponent from "../components/contact/contactComponent"

const Contact = () => {
  return <ContactComponent />
}

export default Contact
